body {
  font-size: 0.93rem;
}

.hidden {
  display: none;
}

/**
 * Header navigation
 */

.nav-link {
  padding-bottom: 4px;
}

/**
 * Experimental anchor styling
 */

a:link {
  color: #223355;
  text-decoration: none;
}
a:visited {
  color: #223355;
  text-decoration: none;
}
a:hover {
  color: #909090;
  text-decoration: none;
}
a:active {
  color: #606060;
  text-decoration: none;
}

a:link.btn {
  color: #ffffff;
}
a:visited.btn {
  color: #ffffff;
}
a:hover.btn {
  color: #ffffff;
  text-decoration: none;
}
a:active.btn {
  color: #ffffff;
  text-decoration: none;
}

/**
 * Global text styling
 */

.text-note {
  font-size: 13.3px;
}

/**
 * Global button styling
 */

button.btn-icon {
  padding: 2px 6px;
  font-size: 13.3px;
}

button.btn-icon svg {
  width: 14px;
  height: 14px;
}

/**
 * Modals
 */

.modal .modal-header, .modal-body, .modal-footer {
  padding-left: 32px;
  padding-right: 32px;
}

/**
 * Tags Editor
 */

.react-tagsinput {
  border-radius: 4px;
}

div .react-tagsinput--focused {
  border-color: #83befd;
  border-width: 1px;
  box-shadow: 0px 0px 3px 3px #c4dffe;
}

span .react-tagsinput-tag {
  font-size: 15px;
  background-color: #f9fcff !important;
  color: #6396a6;
  font-weight: normal;
  border-radius: 3px;
  border-color: #d1d8ef;
  border-style: solid;
  border-width: 1px;
  padding: 2px 6px 2px 6px;
}

input.react-tagsinput-input {
  font-size: 15px;
}

/**
 * List items
 */

 .list-group-item {
   border-top: 0px;
   border-bottom: 0px;
   border-left: 1px;
   border-right: 0px;
 }

 .list-group-item:last-child {
    border-radius: 4px !important;
 }

 .list-group-item:active {
   border-left: 3px;
   border-top: 0px;
 }

/**
 * State
 */

 .make-readonly {
   pointer-events: none;
   cursor: not-allowed;
 }

/**
 * Ace Editor
 */

.ace_gutter {
  z-index: 0;
}

/**
 * Forms
 */

.form-control:disabled, .form-control[readonly] {
  background-color: #f2f2f2;
  border-color: #e6e6e6;
  color: #999;
}
