.workflow-library-pane {
}

.card .workflow-library-pane {
  width: 350px;
}

.workflow-library-components {
  min-height: 740px;
  max-height: calc(100vh - 300px);
  height: calc(100vh - 300px);
  overflow: scroll;
}

.workflow-library-table {
}

.workflow-library-table:last-child {
  margin-bottom: 0px;
}

.workflow-library-table td {
  padding: 10px;
}

.workflow-library-table svg {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.workflow-library-icon {
  border-right-width: 0px !important;
  padding-right: 0px !important;
}

.workflow-library-processor {
  cursor: grab;
  line-height: 138%;
  border-left-width: 0px !important;
  width: 100%;
}
