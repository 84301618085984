.appearance-templates-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 3px;
}

.template-class {
  vertical-align: top;
  width: 20%;
  font-size: 13px;
  text-align: right;
  padding-right: 10px;
  word-wrap: break-word;
  max-width: 1px;
}

.template-template {
  vertical-align: top;
  background: #e8e8e8;
  padding: 2px 5px 2px 10px;
  border-radius: 3px;
  font-size: 13px;
  word-wrap: break-word;
  max-width: 1px;
}

.template-template-editor {
  vertical-align: top;
  background: #ffffff;
  padding: 0px;
  font-size: 13px;
  word-wrap: break-word;
  max-width: 1px;
}

.template-template-editor .form-group {
  margin-bottom: 0px;
}

.template-actions {
  vertical-align: top;
  width: 18%;
  font-size: 13px;
  text-align: right;
  padding-right: 10px;
  word-wrap: break-word;
  max-width: 1px;
}

.appearance-container {
  margin-bottom: 15px;
}