.saved-queries-default {
  vertical-align: top;
  width: 5%;
  font-size: 13px;
  text-align: right;
  padding-top: 5px;
  padding-right: 10px;
  word-wrap: break-word;
  max-width: 1px;
}

.saved-queries-actions {
  vertical-align: top;
  width: 15%;
  font-size: 13px;
  text-align: right;
  padding-right: 10px;
  word-wrap: break-word;
  max-width: 1px;
}

.query-interpreters-dropdown .dropdown-menu {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  min-width: 6rem;
}

.query-interpreters-dropdown .dropdown-item {
  padding-left: 16px;
  padding-right: 16px;
}

.query-interpreters-dropdown .dropdown-item.active {
  background-color: #6c757d;
}

