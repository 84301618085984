/**
 * All rows
 */

.blob-header-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.header-message-row {
  padding-top: 20px;
}

/**
 * Repo name
 */

.repo-name-row {
  padding-top: 16px;
  padding-bottom: 0px;
}

.repo-name-row h5 {
  padding-bottom: 0px;
  margin-bottom: 4px;
}

/**
 * Description and topics
 */

.repo-description-row {
  padding-top: 0px;
  padding-bottom: 7px;
}

.tag-badge {
  background-color: #f1f8ff;
  color: #0366d6;
  font-weight: normal;
  border-radius: 3px;
  padding: 8px 8px 8px 8px;
}

/**
 * Tabs
 */

.main-tabs .nav-tabs .nav-link {
  padding: 0.4rem 0.8rem;
}

.main-tabs .nav-tabs .nav-link.active {
  border-top-color: rgb(227, 98, 9);
  border-top-width: 3px;
}

/**
 * Content
 */

.content-row {
  padding-top: 13px;
}

.content-row-wide {
  padding-top: 13px;
}

.content-row-bottom {
  padding-bottom: 13px;
}

.card-header {
  padding: 0.4rem 1.0rem;
  font-size: 0.87rem;
}

.header-options .btn {
  padding: 3px 6px;
  line-height: 0px;
}

.header-options .octicon {
  width: 12px;
  height: 12px;
}

.header-options svg {
  width: 12px;
  height: 12px;
}

/**
 * Additional Media Formats
 */

@media (min-width: 1500px) {
  .content-row-wide {
    max-width: 1440px;
  }
}

@media (min-width: 1800px) {
  .content-row-wide {
    max-width: 1740px;
  }
}

@media (min-width: 2100px) {
  .content-row-wide {
    max-width: 2040px;
  }
}
