.playground-chat-node-separator {
  margin: 0px;
}

.playground-chat-node .card-body {
  padding: 15px;

  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
