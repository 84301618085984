.properties {
  width: 100%;
  border-collapse: separate;
  border-spacing: 3px;
}

.prop-name-column {
  width: 30%;
  font-size: 13px;
  text-align: right;
  padding-right: 10px;
  word-wrap: break-word;
  max-width: 1px;
}

.prop-value-column {
  background: #e8e8e8;
  padding: 2px 5px 2px 10px;
  border-radius: 3px;
  font-size: 13px;
  word-wrap: break-word;
  max-width: 1px;
}

.vertex-edge-actions-column {
  width: 12%;
  font-size: 13px;
  text-align: right;
  padding-right: 10px;
  word-wrap: break-word;
  max-width: 1px;
}

.vertex-edge-actions-column .octicon {
  width: 16px;
  height: 16px;
}

.vertex-edge-actions-column .btn {
  padding: 4px 6px;
  line-height: 0px;
  margin-left: 3px;
}
