.breadcrumb {
  padding: 5px 0 5px 15px;
  border-radius: 3px;
  border-style: solid;
  border-width: 0 0 0 4px;
  border-color: #cccccc;
  background: #f5f5f5;
}

.properties {
  width: 100%;
  border-collapse: separate;
  border-spacing: 3px;
}

.prop-name-column {
  width: 35%;
  font-size: 13px;
  text-align: right;
  padding-right: 10px;
  word-wrap: break-word;
  max-width: 1px;
}

.prop-value-column {
  background: #e8e8e8;
  padding: 2px 5px 2px 10px;
  border-radius: 3px;
  font-size: 13px;
  word-wrap: break-word;
  max-width: 1px;
}
