#markdown .card-body {
  margin-top: 4px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 4px;

  line-height: 160%;
}

#markdown h1 {
  font-size: 24px;
  border-bottom-color: rgb(234, 236, 239);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 12px;
  padding-bottom: 8px;
}

#markdown h2 {
  font-size: 22px;
  border-bottom-color: rgb(234, 236, 239);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 12px;
  padding-bottom: 8px;
}

#markdown h3 {
  font-size: 20px;
  margin-bottom: 12px;
  padding-bottom: 8px;
}

#markdown h4 {
  font-size: 18px;
  margin-bottom: 12px;
  padding-bottom: 8px;
}

#markdown p {
  margin-bottom: 10px;
}

#markdown pre {
  background-color: rgb(246, 248, 250);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 14px;
  line-height: 150%;
}

#markdown code {
  background-color: rgb(246, 248, 250);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 3px;
  font-size: 0.75rem;
}

#markdown table {
  margin-bottom: 16px;
}

#markdown td, th {
  padding-bottom: 6px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 6px;

/*
  NEXT: Need to be tested
  border-bottom-color: rgb(223, 226, 229);
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-collapse: collapse;
  border-image-outset: 0px;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(223, 226, 229);
  border-left-style: solid;
  border-left-width: 0px;
  border-right-color: rgb(223, 226, 229);
  border-right-style: solid;
  border-right-width: 0px;
  border-top-color: rgb(223, 226, 229);
  border-top-style: solid;
  border-top-width: 0px;
*/
}

#markdown ol li p {
  margin-bottom: 0px;
}

#markdown img {
  max-width: 100%;
}

@media (min-width: 768px) {
  #markdown img {
    max-width: 75%;
  }
}

.md-create .btn {
  padding: 6px 7px 4px 7px;
  margin-bottom: 2px;
  line-height: 0px;
  font-size: 12px;
}

.md-create svg {
  padding-bottom: 2px;
}
