.footer-separator {
  padding-top: 10px;
}

.footer {
  height: 40px;
  font-size: 13.3px;
}

.footer svg {
  width: 18px;
  height: 18px;
}