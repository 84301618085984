/**
 * Control
 */

.workflow-preview {
}

.workflow-preview-surface {
  width: 100%;
  height: 300px;
}

/**
 * Nodes and Edges
 */

.workflow-preview .react-flow__node {
  border: 2px solid #999;
  border-radius: 3px;
  background: white;
}

.workflow-preview .react-flow__node.selected {
  background: #aaaaaa;
}

/**
 * Handles
 */

.workflow-preview .react-flow__handle {
  width: 8px;
  height: 8px;
  border-radius: 3px;
  border: 2px solid #999;
  background-color: white;
  margin: auto;
}

.workflow-preview .react-flow__node.selected .react-flow__handle {
  background-color: #fafafa;
}
