.global-user-avatar {
  color: #aaaaaa;
}

.global-user-avatar .octicon {
  height: 20px;
}

a:link.btn-signin {
  color: #28a745;
}
a:visited.btn-signin {
  color: #28a745;
}
a:hover.btn-signin {
  color: #ffffff;
  text-decoration: none;
}
a:active.btn-signin {
  color: #ffffff;
  text-decoration: none;
}
