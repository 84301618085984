.workflow-selector-container {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.workflow-selector-row {
  padding-bottom: 0px;
  margin-bottom: 3px;
}

.workflow-selector-dropdown {
  width: 300px;
  display: inline-block;
}

.workflow-selector-locked-icon {
  display: inline-block;
  margin-top: 4px;
  margin-right: 10px;
}

.workflow-selector-locked-notice {
  display: inline-block;
  font-size: 13px;
  line-height: 128%;
  width: 180px;
  margin-top: -1px;
  margin-right: 25px;
}

.btn-warning-close {
  border-color: transparent;
  background-color: transparent;
}

.btn-warning-close:hover {
  border-color: transparent;
  background-color: #cff3cd;
}
