.view-query-index {
  width: 5%;
  vertical-align: top;
  font-size: 13.0px;
}

.view-query-details {
  width: 40%;
  text-align: left;
  font-size: 13.0px;
}

.public-item-content-row {
  margin-top: 14px;
}

.public-item-preview-link {
  margin-top: -8px;
  margin-bottom: 8px;
  font-size: 12px;
}