.playground-widget-name {
  font-weight: bolder;
  color: #555;
}

.playground-message-content {
}

.playground-message-content-item {
  position: relative;
}

.playground-message-content-item:hover .playground-message-content-item-controls {
  display: block;
}

.playground-message-content-item-controls {
  display: none;
  position: absolute;
  top: -3px;
  right: -3px;
}

.playground-message-content-item-controls .btn {
  padding-top: 1px;
  padding-bottom: 1px;
}
