.playground-selector-dropdown {
  width: 150px;
  display: inline-block;
}

.session-selector-dropdown {
  width: 370px;
  display: inline-block;
}

.playground-session-select-option p {
  margin-bottom: 0px;
}
