.blob-settings-navigation-list .list-group-item {
  padding: 7px 20px;
  border-left-width: 3px;
}

.blob-settings-navigation-list .list-group-item.active {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: #000;
  border-left-color: rgb(227, 98, 9);
  border-left-width: 3px;
}

.blob-settings-navigation-list .list-group-item:hover, .list-group-item.active:hover {
  background-color: #f5f5f5;
}

.blob-settings-navigation-list a {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.125);
  border-radius: 0px;
}
