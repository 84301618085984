/**
 * React Flow components
 */

.workflow-editor .react-flow__node {
  border: 1px solid #bbb;
  border-radius: 3px;
  background: white;
}

.workflow-editor  .react-flow__node.selected {
  border: 1px solid #555;
  border-radius: 3px;
  background: #fafafa;
}

.workflow-editor .react-flow__edge {
  fill: #bbb !important;
}

.workflow-editor .react-flow__edge.selected {
  fill: #555 !important;
}

/**
 * Custom node styling
 */

.generic-node {
  padding: 5px 10px 5px 10px;
  border-radius: 3px;
}

.generic-node .node-property-row {
  height: 16px;
  margin-top: -1px;
}

.generic-node .node-property-row-last {
  height: 24px;
}

.generic-node .node-property-label {
  display: inline-block;
  color: #999;
  font-size: 8px;
  margin-left: 3px;
  margin-right: 5px;
  margin-bottom: 0px;
}

.generic-node .node-property-type {
  display: inline-block;
  color: #555;
  font-size: 10px;
  margin-right: 5px;
  margin-bottom: 0px;
}

.generic-node .node-property-name {
  display: inline-block;
  color: #000;
  font-size: 12px;
  margin-bottom: 0px;
  margin-right: 7px;
}

/**
 * Handle styling
 */

.workflow-editor .react-flow__handle {
  width: 14px;
  height: 14px;
  border-radius: 4px;
  border: 1px solid #bbb;
  background-color: white;
  margin: auto;
}

.workflow-editor .react-flow__node.selected .react-flow__handle {
  background-color: #fafafa;
  border: 1px solid #999;
}

.workflow-editor .react-flow__handle svg {
  pointer-events: none;
  width: 8px;
  height: 8px;
  margin-left: 2px;
  margin-top: -12px;
  color: #888;
}
