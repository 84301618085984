.explore-content-pane {
  width: 100%;
  padding-right: 6px;
}

.explore-content-pane {
  vertical-align: top;
}

.explore-quick-actions {
  padding-left: 6px;
  text-align: center;
  vertical-align: top;
}

.explore-quick-actions .btn {
  padding: 4px 6px;
  margin-bottom: 2px;
  line-height: 0px;
}

.explore-quick-actions .octicon {
  width: 14px;
  height: 14px;
}

.explore-vertical-divider {
  width: 1px;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  padding-left: 4px;
}

.appearance-container .btn, .query-container .btn {
  padding: 4px 6px;
  margin-bottom: 2px;
  line-height: 0px;
}

.appearance-container .octicon, .query-container .octicon {
  width: 14px;
  height: 14px;
}

hr {
  margin-top: 12px;
  margin-bottom: 12px;
}

.alert {
  font-size: 12px;
  padding: 6px 14px;
  margin-bottom: 0px;
}

.explore-quick-actions hr {
  margin: 6px 0px;
}

.vis-network:focus {
  outline: none;
}
