.legal-content h4 {
  margin-bottom: 15px;
}

.legal-content h5 {
  margin-bottom: 15px;
}

.legal-content h6 {
  margin-bottom: 15px;
}
