.blob-list-row {
  margin-top: 6px;
}

.blob-list-row h5 {
  margin-bottom: 3px;
}

.blob-item-row {
  margin-bottom: 3px;
}
