/**
 * Rows
 */

.blob-download-row {
  padding-bottom: 22px;
  margin-bottom: 4px;
}

.blob-download-row .dropdown-item {
  color: #212529;
  font-size: 0.88rem;
}

.blob-download-row .dropdown .active {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.16);
}

/**
 * Records Table
 */

 .table th, .table td {
  border-top-width: 0px;
}

.react-bootstrap-table-page-btns-ul .active .page-link {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.16);
  border-color: #dee2e6;
}

.react-bootstrap-table-page-btns-ul .page-link {
  color: #212529;
}

.react-bootstrap-table-pagination-total {
  color: #212529;
  font-size: 0.84rem;
}

.react-bootstrap-table th {
  padding: 0.5rem 0.75rem;
}

.react-bootstrap-table td {
  padding: 0.5rem 0.75rem;
}

/**
 * Annotations
 */

.record-selected-query {
  background-color: rgba(0, 0, 0, 0.03);
}

.tagger {
  line-height: 1.8em;
}

.tagger .badge {
  padding: 0.45em 0.8em;
}
