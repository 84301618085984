.workflow-controls-pane-left {
  position: absolute;
  top: 10px;
  left: 12px;
  z-index: 4;
}

.workflow-controls-pane-left .btn {
  padding: 6px 8px;
  line-height: 0px;
}

.workflow-controls-pane-left svg {
  width: 12px;
  height: 12px;
}

.workflow-controls-pane-right {
  position: absolute;
  top: 10px;
  right: 12px;
  z-index: 4;
}

.workflow-controls-pane-right .btn {
  padding: 6px 8px;
  line-height: 0px;
}

.workflow-controls-pane-right svg {
  width: 12px;
  height: 12px;
}
