.playground-editor {
}

.playground-editor-surface {
  width: 100%;
  height: calc(100vh - 340px);
  min-height: 700px;
}

.playground-editor .react-flow__node.selected .card {
  border: 1px solid #999;
}

.playground-editor .react-flow__node.selected .card-header {
  background: #eeeeee;
}
