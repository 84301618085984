.org-profile-row {
  margin-top: 8px;
}

.org-profile-row h5 {
  margin-bottom: 12px;
}

.org-profile-item-row {
  font-size: 13.3px;
  margin-bottom: 2px;
}

.title-tag-badge {
  background-color: #f9fcff !important;
  color: #6396a6;
  font-weight: normal;
  border-radius: 3px;
  border-color: #d1d8ef;
  border-style: solid;
  border-width: 1px;
  padding: 4px 6px 4px 6px;
}
