.main-logo .alert-primary {
  background-color: #f1f8ff;
  border-color: #f1f8ff;
  border-left-color: #b1b8ef;
  border-left-width: 5px;
}

.main-page-banner-row {
  color: #ffffff;
  background-color: #20232a;
  height: 350px;
  text-align: left;
  margin-right: 0px;
}

.main-page-banner-row .container {
  padding-right: 0px;
}

.main-page-banner-row p {
  color: #bebebe;
  font-size: 16px;
  font-weight: 250;
  padding-top: 12px;
}

.main-page-banner-row .col-sm-8 {
  padding-right: 0px;
}

.main-page-section-title-row {
  padding-top: 28px;
  padding-bottom: 18px;
  text-align: center;
}

.main-page-section-title-row hr {
  margin-top: 4px;
  margin-bottom: 20px;
}

.main-page-first-subsection-title-row {
  margin-top: -4px;
  padding-top: 0px;
  padding-bottom: 18px;
  text-align: center;
}

.main-page-subsection-title-row {
  padding-top: 28px;
  padding-bottom: 18px;
  text-align: center;
}

.main-card-banner {
  height: 180px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.main-card-icon {
  margin-right: 8px;
  margin-bottom: 3px;
  width: 0.9em;
  height: 0.9em;
}
