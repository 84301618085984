.execution-event-item {
  font-size: 13.5px;
}

.execution-event-item-icon-cell {
  width: 30px;
}

.execution-event-item-icon-cell svg {
  width: 18px;
  height: 18px;
}

.execution-event-item-sub-icon-cell {
  width: 26px;
}

.execution-event-item-sub-icon-cell svg {
  width: 14px;
  height: 14px;
}

.execution-event-item table {
  width: 100%;
  margin-bottom: 4px;
}

.execution-event-item-event-type-row {
  margin-bottom: 2px;
}

.execution-event-item-event-details-row {
  margin-bottom: 1px;
  max-width: 100px;
}

.execution-event-item .badge {
  font-size: 13.5px;
}

